import { useEffect } from "react";
import { useVotos } from "./VotosContext"
import { useNavigate } from "react-router-dom";

export function RequireAuthVote({ children }: { children: JSX.Element }) {
    const { votos } = useVotos()
    const history = useNavigate()

    useEffect(() => {
        if(votos===0){
            history('/compra')
        }
    }, [votos])

    return children
}