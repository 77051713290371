
import logoIMG from '../assets/img/logo.png'
import logoVibx from '../assets/img/vibx.png'
import anaLogo from '../assets/img/anaLogo.png'
import '../styles/login.scss'
import { Button } from '../componentes/Button'
import { Navigate, useNavigate } from 'react-router-dom'
import { FormEvent, useEffect, useState } from 'react'
import api from '../services/api'
import { useIsAuth } from '../hooks/useAuth'
import { toast } from 'react-toastify'


export function Login() {



    const history = useNavigate()
    const [login, setLogin] = useState('')
    const [senha, setSenha] = useState('')
    const [soujurado, setSoujurado] = useState('hiddensoujurado')
    const [exibeLogin, setExibeLogin] = useState('showlogin')

    async function handleLogin(event: FormEvent) {
        event.preventDefault()

        const response = await api.post('user/login', new URLSearchParams({
            email: login,
            password: senha
        }))
            .then((response) => {
                localStorage.setItem("token", response.data.token)
                history('/')
            })
            .catch((e) => {
                if (e.response) {
                    // Is this the correct way?
                    toast.warning(e.response?.data.error)
                }

            })
    }

    async function showLogin(event: FormEvent) {
        event.preventDefault()
        setSoujurado('hiddensoujurado')
        setExibeLogin('showlogin')

    }

    async function handleCompra(event: FormEvent) {
        event.preventDefault()
        history('/cadastro')
    }

    if (useIsAuth()) {
        return <Navigate to="/" state={{ from: '/login' }} replace />;
    }

    return (
        <div id="page-auth">
            <aside>
                <strong>Concurso PPK Brasil</strong>
                <p>Faça Login para escolher a PPK mais bonita do Brasil</p>
            </aside>
            <main>
                <div className="main-content">
                    <div className='div-logo'>
                        <img src={anaLogo} alt="Logo" />
                        <img src={logoVibx} alt="Logo" />
                        <div className="separator">Apresentam</div>
                        <img src={logoIMG} alt="Logo" />
                    </div>

                    <button onClick={handleCompra} className="create-room-google">
                        Me tornar jurado
                    </button>
                    <div className={exibeLogin}>
                        <div className="separator">Já tem Login?</div>
                        <form onSubmit={handleLogin}>
                            <input
                                type="text"
                                placeholder='Login'
                                onChange={event => setLogin(event.target.value)}
                                value={login}
                            />
                            <input
                                type="password"
                                placeholder='Senha'
                                onChange={event => setSenha(event.target.value)}
                                value={senha}
                            />
                            <Button>Entrar</Button>
                        </form>
                    </div>
                    <div className={soujurado}>
                        <Button onClick={showLogin}>Já sou jurado</Button>
                    </div>
                </div>
            </main>
        </div>
    )
}

