import React, { createContext, useContext, useState, useEffect } from 'react';
import api from '../services/api';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';



type VotosContextType = {
    votos: number | null;
    refreshData: () => void;
};

const VotosContext = createContext<VotosContextType | undefined>(undefined);

export const VotosProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const history = useNavigate();
    const [votos, setVotos] = useState<number | null>(null);

    const fetchData = () => {
        api.get('candidatas/getVotos')
            .then((response) => {
                if (response) {
                    setVotos(Number(response.data.count));
                } else {
                    localStorage.clear();
                    toast.warning('Sessão Expirada');
                    history('/login');
                }
            })
            .catch((e) => {
                if (e.response) {
                    localStorage.clear();
                    toast.warning('Sessão Expirada, faça Login novamente');
                    history('/login');
                }
            });
    };

    useEffect(() => {
        fetchData();
    }, [history])

    return (
        <VotosContext.Provider value={{ votos, refreshData: fetchData }}>
            {children}
        </VotosContext.Provider>
    );
};

export const useVotos = () => {
    const context = useContext(VotosContext);
    if (!context) {
        throw new Error('useCandidatas must be used within a CandidatasProvider');
    }
    return context;
};
