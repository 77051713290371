
import '../styles/resultado.scss'
import { useNavigate } from 'react-router-dom'
import { useEffect,  useState } from 'react'
import api from '../services/api'
import { Header } from '../componentes/Header'
import {Countdown} from '../componentes/Countdown'
import { toast } from 'react-toastify'

type ResultadoType = {
    nome: string
    votos: number
    id: string
}

export function Resultado(){
    const history = useNavigate()
    const [count, setCount] = useState(1);
    const [status, setStatus] = useState<number>(1);
    const [time, setTime] = useState<Date>(new Date());
    const [msg, setMsg] = useState<string>('');
    const [resultado, setResultado] = useState<ResultadoType[]>([
        {nome: "Carregando...", votos: 0, id: ""},
    ])
    
    useEffect(() => {

        const carregaDados = async () =>{
            api.get("candidatas/rankingCandidatas")
            .then((response) => {
                setResultado(response.data.data)
                setCount(response.data.count)
                setStatus(response.data.status)
                setTime(new Date(response.data.datafinal))
                setMsg(response.data.msg)
            })
            .catch((e) =>{
                if (e.response) {
                    // Is this the correct way?
                    toast.error(e.response?.data.error)
                    localStorage.clear()
                    history('/login')
                }
            })
        }
        carregaDados()

    }, [ ])
    
    return(
        <div id="page-room">
            <Header />
            {status===1 && (
                <main className='content'>
                    <div className='room-title'>
                        <h1>Resultado Final</h1>
                    </div> 
                    
                    <div className='question-list'>
                    
                    
                        <div className='list-flex'>
                            <div className='question-table'>
                                <label className='head-ranking ranking'>Candidata</label>
                                <label className='head-ranking name'>%</label>
                            </div>
                            {resultado.map(resultadoObj => {
                                return(    
                                    <div className='question-table' key={resultadoObj.id}>
                                        <label className='item-ranking ranking'>{resultadoObj.nome}</label>
                                        <label className='item-ranking name'>{(resultadoObj.votos/count).toLocaleString('pt-br',{style: 'percent',  minimumFractionDigits: 2})}</label>
                                    </div>
                                )
                            })}
                            
                        </div>
                    </div>
                </main>
            )}

            {status===0 && (
                <main className='content'>
                    <Countdown time={time} msg={msg} ></Countdown>
                </main>
            )}
                    
                
            
        </div>
    )
}