import React, { useState, useEffect } from 'react'

type DateCountdownType = {
  time:Date,
  msg: string
}


export function Countdown({time,msg}:DateCountdownType) {
  const targetDate = time;
  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  function calculateTimeRemaining() {
    const currentTime = new Date();
    const difference = targetDate.getTime() - currentTime.getTime();

    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((difference / 1000 / 60) % 60);
    const seconds = Math.floor((difference / 1000) % 60);

    return { days, hours, minutes, seconds };
  }

  return (
    <div>
      <h1>Resultado será divulgado em:</h1>
      <h2>
        {timeRemaining.days} dia(s),{' '}
        {timeRemaining.hours} hora(s),{' '}
        {timeRemaining.minutes} minuto(s), {timeRemaining.seconds} segundo(s)
      </h2>
      <p>
        {msg}
      </p>
    </div>
  )
}
