import './componentes/Button'
import './styles/global.scss'
import 'react-toastify/dist/ReactToastify.css'

import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { Home } from './pages/Home'
import { Login } from './pages/Login'
import { RequireAuth } from './context/RequireAuth'
import { RequireAuthVote } from './context/RequireAuthVote'
import { Vote } from './pages/Vote'
import { Page404 } from './pages/Page404'
import { Cadastro } from './pages/Cadastro'
import { Compra } from './pages/Compra'
import { Valida } from './pages/Valida'
import { Pedido } from './pages/Pedido'
import { Resultado } from './pages/Resultado'
import { ToastContainer } from 'react-toastify'
import { VotosProvider } from './context/VotosContext'
import { MSG } from './pages/MSG'



function App() {

  return (
    <BrowserRouter>
      <ToastContainer />

      <Routes>
        <Route path="*" element={<Page404 />} />        
        <Route path="/login" element={<Login />} />
        <Route path="/cadastro" element={<Cadastro />} />
        <Route path="/" element={
          <RequireAuth>
            <VotosProvider>
              <RequireAuthVote>
                <Resultado />
              </RequireAuthVote>
            </VotosProvider>
          </RequireAuth>
        }
        />

        <Route path="/vote/:id" element={
          <RequireAuth>
            <VotosProvider>
              <RequireAuthVote>
                <Resultado />
              </RequireAuthVote>
            </VotosProvider>
          </RequireAuth>
        }
        />

        <Route path="/compra" element={
          <RequireAuth>
            <VotosProvider>
              <Resultado />
            </VotosProvider>
          </RequireAuth>
        }
        />

        <Route path="/pedido" element={
          <RequireAuth>
            <VotosProvider>
              <Resultado />
            </VotosProvider>
          </RequireAuth>
        }
        />

        <Route path="/resultado" element={
          <RequireAuth>
            <VotosProvider>
              <Resultado />
            </VotosProvider>
          </RequireAuth>
        }
        />

        <Route path="/valida/:id" element={<Valida />} />

      </Routes>
    </BrowserRouter>
  );
}

export default App;
